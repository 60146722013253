import angular from 'angular'
import { values, forEach, each } from 'lodash-es'
import { customer_wireline_path } from '../../../helpers/rails-routes'
import DOMPurify from 'dompurify'

// All columns which contain cost or usage values should use the following
// naming pattern: ..._cost_total ..._cost_average ..._usage_total ..._usage_average
// This pattern also should be aplied to their JSON keys
angular.module('ArgonModule').service('crWirelineColumnsService', [
  'dateFilter',
  'formatter',
  function (dateFilter, formatter) {
    const self = this

    const returns = () => ({
      init: (config) => {
        self.accounting_types_columns = config.accounting_types
        self.columns.push({
          id: 'business_unit',
          title: 'Business Unit',
          description: `(${values(self.accounting_types_columns).join(', ')})`,
          group: 'Employee Details',
        })

        forEach(self.accounting_types_columns, (type_name, type_id) => {
          self.columns.push({
            id: type_id,
            title: type_name,
            // we don't need to display a checkbox for this column
            group: '',
            className: 'dt-right',
            data: (row) => JSON.parse(row[type_id] || '[]').join('<br/>'),
          })
        })

        transformColumns()
      },
      columns_grouped_for_select: () => {
        const column_groups = {}
        each(self.columns, (column_definition) => {
          if (!column_groups[column_definition.group]) column_groups[column_definition.group] = []
          column_groups[column_definition.group].push(column_definition)
          return true
        })
        return column_groups
      },
      columns_as_object: () => self.columns_as_object,
      findDefinitions: findDefinitions,

      defaultColumns: () => [
        'username',
        'number',
        'status',
        'wireline_subtype',
        'business_unit',
        'carrier',
        'wireline_location',
      ],
      allColumns: () => self.columns.map((obj) => obj.id),
      lockedColumns: () => self.columns.filter((obj) => obj.locked).map((obj) => obj.id),
      groupColumns: (group) =>
        self.columns.filter((obj) => obj.group == group).map((obj) => obj.id),
      selectValidColumns: (columns) =>
        columns.filter((column) => self.columns_as_object[column] !== undefined),
    })

    //
    const findDefinitions = (columns) => {
      const result = []
      each(columns, (column_id) => {
        if (column_id != 'business_unit') {
          result.push(self.columns_as_object[column_id])
        } else {
          forEach(self.accounting_types_columns, (type_name, type_id) =>
            result.push(self.columns_as_object[type_id])
          )
        }
      })
      return result
    }

    const transformColumns = () => {
      self.columns_as_object = {}
      each(self.columns, (column_definition) => {
        self.columns_as_object[column_definition.id] = column_definition
        return true
      })
    }

    self.columns = [
      // Device Details
      {
        id: 'username',
        title: 'Name',
        group: 'Device Details',
        locked: true,
        className: 'column_reordering_disabled',
        data: (row, type) => {
          if (!row.username) row.username = ''
          if (type == 'display') {
            if (row.customer_id && row.wireline_id) {
              let username = row.username.substring(0, 15)
              if (username != row.username) username += '...'
              return `<a href='${customer_wireline_path(row.customer_id, row.wireline_id)}'
                  class='indicator secondary-branded secondary-branded-border'
                  title='${DOMPurify.sanitize(row.username)}'>${DOMPurify.sanitize(username)}</a>`
            } else {
              return formatter.formatPhone(row.number)
            }
          } else {
            return DOMPurify.sanitize(row.username)
          }
        },
      },
      {
        id: 'number',
        title: 'Number',
        group: 'Device Details',
        locked: true,
        className: 'column_reordering_disabled dt-right',
        data: (row, type) => {
          if (type == 'display') {
            if (row.customer_id && row.wireline_id) {
              return `<a href='${customer_wireline_path(row.customer_id, row.wireline_id)}'
                class='indicator secondary-branded secondary-branded-border'
                title='${DOMPurify.sanitize(row.username)}'>${formatter.formatPhone(row.number)}</a>`
            } else {
              return formatter.formatPhone(row.number)
            }
          } else if (type == 'filter') {
            return row.number + ' ' + formatter.formatPhone(row.number)
          } else {
            return row.number
          }
        },
      },
      {
        id: 'status',
        title: 'Status',
        group: 'Device Details',
        className: 'dt-right',
        data: (row, type) => {
          if (type == 'display') {
            switch (row.status) {
              case 'active':
                return '<span class=green>Active</span>'
              case 'suspended':
                return '<span class=yellow>Suspended</span>'
              case 'cancelled':
                return '<span class=red>Cancelled</span>'
              default:
                return ''
            }
          } else {
            return row.status || ''
          }
        },
      },
      {
        id: 'wireline_subtype',
        title: 'Wireline Make',
        group: 'Device Details',
        className: 'dt-right',
        data: (row, type) => {
          if (!row.wireline_subtype) row.wireline_subtype = ''
          if (type == 'display') {
            return `<div style='white-space: normal;'>${row.wireline_subtype}</div>`
          } else {
            return row.wireline_subtype
          }
        },
      },
      {
        id: 'wireline_location',
        title: 'Location',
        group: 'Device Details',
        className: 'dt-right',
        data: (row) => row.wireline_location,
      },
      {
        id: 'employee_number',
        title: 'Employee Number',
        group: 'Device Details',
        className: 'dt-right',
        data: (row) => DOMPurify.sanitize(row.employee_number),
      },
      {
        id: 'wireline_email',
        title: 'Email',
        group: 'Device Details',
        className: 'dt-right',
        data: (row) => DOMPurify.sanitize(row.wireline_email),
      },
      {
        id: 'wireline_used_for',
        title: 'Used For',
        group: 'Device Details',
        className: 'dt-right',
        data: (row) => DOMPurify.sanitize(row.wireline_used_for),
      },
      {
        id: 'wireline_warehouse',
        title: 'Warehouse',
        group: 'Device Details',
        className: 'dt-right',
        data: (row) => DOMPurify.sanitize(row.wireline_warehouse),
      },
      {
        id: 'service_address',
        title: 'Service Address',
        group: 'Device Details',
        className: 'dt-right',
        data: (row, type) => {
          if (!row.service_address) row.service_address = ''
          if (type == 'display') {
            if (row.customer_id && row.wireline_id) {
              let service_address = row.service_address.substring(0, 20)
              if (service_address != row.service_address) service_address += '...'
              return `<div title='${row.service_address}'>${service_address}</div>`
            } else {
              return formatter.formatPhone(row.number)
            }
          } else {
            return row.service_address
          }
        },
      },
      // Employee Details
      {
        id: 'employee_manager',
        title: 'Employee Manager',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_manager,
      },
      {
        id: 'employee_name',
        title: 'HR Employee Name',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_name,
      },
      {
        id: 'employee_status',
        title: 'Employment Status',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_status,
      },
      {
        id: 'employee_title',
        title: 'Employee Title',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_title,
      },
      {
        id: 'employee_email',
        title: 'Employee Email',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_email,
      },
      {
        id: 'employee_department_name',
        title: 'Employee Department Name',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_department_name,
      },
      {
        id: 'employee_hr_user_id',
        title: 'Employee User ID',
        group: 'Employee Details',
        className: 'dt-right',
        data: (row) => row.employee_hr_user_id,
      },
      // Carrier Details
      {
        id: 'carrier',
        title: 'Carrier',
        group: 'Carrier Details',
        className: 'dt-right',
        data: (row) => row.carrier,
      },
      {
        id: 'wireline_account',
        title: 'Account',
        group: 'Carrier Details',
        className: 'dt-right',
        data: (row) => row.wireline_account,
      },
      // Costs & Averages
      {
        id: 'total_cost_total',
        title: 'Total Spend',
        group: 'Costs & Averages',
        className: 'dt-right',
        data: (row) => formatter.formatMoney(row.total_cost_total),
      },
      // {
      //   id: 'total_cost_average',
      //   title: 'Cost (avg)',
      //   group: 'Costs & Averages',
      //   className: 'dt-right',
      //   data: (row) => formatter.formatMoney(row.total_cost_average),
      // },
      // {
      //   id: 'credits_cost_total',
      //   title: 'Total Credits',
      //   group: 'Costs & Averages',
      //   className: 'dt-right',
      //   data: (row) => formatter.formatMoney(row.credits_cost_total),
      // },
      // {
      //   id: 'features_cost_average',
      //   title: 'Features Cost (avg)',
      //   group: 'Costs & Averages',
      //   className: 'dt-right',
      //   data: (row) => formatter.formatMoney(row.features_cost_average),
      // },
      // {
      //   id: 'long_distance_cost',
      //   title: 'Long Distance Cost',
      //   group: 'Costs & Averages',
      //   className: 'dt-right',
      //   data: (row) => formatter.formatMoney(row.long_distance_cost),
      // },
    ]

    return returns()
  },
])
